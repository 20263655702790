<template>
  <div class="page_wrapper">
    <div class="list">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
        @click="handleClick(item)"
      >
        <div class="icon">
          <icon-font :icon="item.icon" />
        </div>
        <div class="detail">
          <div class="title">{{ item.title }}</div>
          <div class="description">{{ item.description }}</div>
        </div>
        <div class="arrow">
          <icon-font icon="arrow-right" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Toast
} from "vant";
import { STEP_LIST, SUBJECT_TYPE_LIST } from "./config";
export default {
  components: {
    Toast
  },
  data() {
    return {
      list: [
        {
          icon: "store",
          title: "小微商户申请",
          description: "不需要营业执照，直接提交资料",
          type: "",
          url: "#"
        },
        {
          icon: "boss",
          title: "个体工商户申请",
          description: "需要营业执照，结算至银行卡",
          type: SUBJECT_TYPE_LIST.SUBJECT_TYPE_INDIVIDUAL,
          url: {
            name: "Prepare",
            query: {
              type: SUBJECT_TYPE_LIST.SUBJECT_TYPE_INDIVIDUAL
            }
          }
        },
        {
          icon: "company",
          title: "公司企业申请",
          description: "需要企业营业执照，结算至对公账户",
          type: SUBJECT_TYPE_LIST.SUBJECT_TYPE_ENTERPRISE,
          url: {
            name: "Prepare",
            query: {
              type: SUBJECT_TYPE_LIST.SUBJECT_TYPE_ENTERPRISE
            }
          }
        },
        {
          icon: "dangweijigou",
          title: "党政，机关及事业单位申请",
          description: "需要事业单位法人证书，结算至对公账号",
          type: SUBJECT_TYPE_LIST.SUBJECT_TYPE_INSTITUTIONS,
          url: "#"
          // url: {
          //   name: "Prepare",
          //   query: {
          //     type: SUBJECT_TYPE_LIST.SUBJECT_TYPE_INSTITUTIONS
          //   }
          // }
        },
        {
          icon: "xianaixin",
          title: "其他组织申请",
          description: "需要提供营业执照，民办非证书，结算至对公账号",
          type: SUBJECT_TYPE_LIST.SUBJECT_TYPE_OTHERS,
          url: "#"
          // url: {
          //   name: "Prepare",
          //   query: {
          //     type: SUBJECT_TYPE_LIST.SUBJECT_TYPE_OTHERS
          //   }
          // }
        },
        {
          icon: "saoma",
          title: "一码付申请",
          description: "需要营业执照，直接提交资料结算至支付宝账户",
          type: "",
          url: "#"
          // url: "/one-yard"
        },
        {
          icon: "shoukuanma",
          title: "收款设备",
          description: "收款音响、云喇叭、智能POS等",
          type: "",
          url: "#"
        }
      ]
    };
  },
  methods: {
    handleClick(item) {
      if (item.url === "#") {
        return Toast("该模块正在开发中，敬请期待");
      } else {
        this.$router.push(item.url);
      }
    }
  },
  created() {
    document.title = STEP_LIST[0];
  }
};
</script>
<style lang="less" scoped>
.page_wrapper {
  padding-top: 20px;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 40px;

  .list {
    .item {
      background: #ffffff;
      box-shadow: 0 0 4px 0 rgba(144, 143, 143, 0.25);
      border-radius: 5px;
      padding: 20px 10px 20px 18px;
      display: flex;
      align-items: center;

      &:not(:first-of-type) {
        margin-top: 10px;
      }

      .icon {
        width: 40px;
        height: 40px;

        .svg-icon {
          width: 100%;
          height: 100%;
        }
      }

      .detail {
        margin-left: 16px;
        flex: 1;
        overflow: hidden;

        .title {
          font-size: 17px;
          color: #0d0c0c;
          letter-spacing: 0;
          line-height: 17px;
        }

        .description {
          margin-top: 10px;
          font-size: 11px;
          color: rgba(0, 0, 0, 0.4);
          letter-spacing: 0;
          line-height: 14px;
        }
      }
    }
  }
}
</style>